:root {
  $white-100: oklch(100% 0 0);
  $white-97: oklch(97% 0 0);
  $white-94: oklch(94% 0 0);
  $white-65: oklch(65% 0 0);
  $white-45: oklch(45% 0 0);
  $white-35: oklch(35% 0 0);

  --color-text: #{$white-35};
  --color-text-inverted: #{$white-100};
  --color-text-muted: #{$white-65};

  --color-background: #{$white-97};
  --color-background-inverted: #{$white-35};
  --color-background-section: #{$white-100};

  --color-border: #{$white-94};
  --color-border-inverted: #{$white-45};

  --color-backdrop: oklch(0% 0 0 / 0.7);

  --color-token-violet: oklch(45% 0.2 350);
  --color-token-red: oklch(45% 0.2 30);
  --color-token-blue: oklch(45% 0.2 270);
  --color-token-cyan:  oklch(40% 0.2 230);
  --color-token-green: oklch(45% 0.15 145);
  --color-token-gray: oklch(35% 0 0);

  --color-light-blue: #DDEDFF;

  --size-text-s: 1.4rem;
  --size-text-m: 1.6rem;

  --line-height-text-m: 1.5;
  --line-height-text-s: 1.5;

  --max-width-section: 850px;

  --viewport-width-sm: 600px;

  --timing-instant: 0ms;
  --timing-normal: 300ms;
  --timing-slowed: 500ms;
}