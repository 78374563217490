.figure {
  padding: 0;
  margin: 2em 0;
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  figcaption {
    font-size: var(--size-text-s);
    text-align: left;
    margin-top: 0.25em;
  }
}
