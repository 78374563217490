@use "resources/styles/tokens" as tokens;

.block-list {
  display: flex;
  flex-direction: column;
  gap: tokens.$size-spacing-sm;

  list-style: none;

  margin: 0;
  padding: 0;

  & > li {
    padding: 0;
  }
}