@use "../utilities/initial-variables" as iv;
@use "../utilities/mixins" as mx;

$container-offset: 2 * iv.$gap !default;
$container-max-width: iv.$fullhd !default;

.#{iv.$class-prefix}container {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: 100%;

  &.#{iv.$class-prefix}is-fluid {
    max-width: none !important;
    padding-left: iv.$gap;
    padding-right: iv.$gap;
    width: 100%;
  }

  @include mx.desktop {
    max-width: iv.$desktop - $container-offset;
  }

  @include mx.until-widescreen {
    &.#{iv.$class-prefix}is-widescreen:not(.#{iv.$class-prefix}is-max-desktop) {
      max-width: min(iv.$widescreen, $container-max-width) - $container-offset;
    }
  }

  @include mx.until-fullhd {
    &.#{iv.$class-prefix}is-fullhd:not(.#{iv.$class-prefix}is-max-desktop):not(
        .#{iv.$class-prefix}is-max-widescreen
      ) {
      max-width: min(iv.$fullhd, $container-max-width) - $container-offset;
    }
  }

  @include mx.widescreen {
    &:not(.#{iv.$class-prefix}is-max-desktop) {
      max-width: min(iv.$widescreen, $container-max-width) - $container-offset;
    }
  }

  @include mx.fullhd {
    &:not(.#{iv.$class-prefix}is-max-desktop):not(
        .#{iv.$class-prefix}is-max-widescreen
      ) {
      max-width: min(iv.$fullhd, $container-max-width) - $container-offset;
    }
  }
}
