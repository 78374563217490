@mixin source-sans-3($name, $weight, $style) {
  @font-face {
    font-family: "Source Sans 3";
    font-style: $style;
    font-weight: $weight;
    font-display: optional;
    size-adjust: 106.25%;

    src: url("/resources/fonts/source-sans-3/#{$name}.woff2") format("woff2"),
    url("/resources/fonts/source-sans-3/#{$name}.woff") format("woff"),
    url("/resources/fonts/source-sans-3/#{$name}.ttf") format("truetype");
  }
}

@supports not (font-variation-settings: normal) {
  @include source-sans-3(Regular, 400, normal);
  @include source-sans-3(Italic, 400, italic);
  @include source-sans-3(SemiBold, 600, normal);
}

@supports (font-variation-settings: normal) {
  @font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 200 900;
    font-display: optional;
    size-adjust: 106.25%;

    src: url("/resources/fonts/source-sans-3/Variable.woff2") format("woff2-variations");
    src: url("/resources/fonts/source-sans-3/Variable.woff2") format('woff2') tech('variations');
  }

  @font-face {
    font-family: "Source Sans 3";
    font-style: italic;
    font-weight: 200 900;
    font-display: optional;
    size-adjust: 106.25%;

    src: url("/resources/fonts/source-sans-3/ItalicVariable.woff2") format("woff2-variations");
    src: url("/resources/fonts/source-sans-3/ItalicVariable.woff2") format('woff2') tech('variations');
  }
}
