@use "resources/styles/tokens" as tokens;

@import "~bulma/sass/layout/container";

.container {
  @media screen and (width < tokens.$breakpoint-width-sm) {
    margin-bottom: 7.2rem;
  }
}

.container--fluid {
  max-width: none;
  padding-left: tokens.$size-spacing-sm;
  padding-right: tokens.$size-spacing-sm;
  width: 100%;
}