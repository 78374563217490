@use "resources/styles/tokens" as tokens;

@mixin when-visible {
  @media screen and (width > 1216px) {
    @content;
  }
}

.island-layout {
  @include when-visible {
    display: flex;
    margin-block: -(tokens.$size-spacing-md);
  }
}

.island-layout--reverse {
  @include when-visible {
    flex-direction: column-reverse;
  }
}

.island {
  @include when-visible {
    position: absolute;
    right: 0;
    transform: translate(calc(100% + #{tokens.$size-spacing-lg}), -#{tokens.$size-spacing-md});

    max-width: 300px;

    font-style: italic;

    //noinspection CssInvalidPropertyValue
    animation-duration: auto;
    animation-timing-function: linear;
    //noinspection CssInvalidPropertyValue
    animation-direction: both;
    animation-name: reveal;
    //noinspection CssInvalidFunction
    animation-timeline: view();
    animation-range: entry 25% cover 50%;

    > .active-block {
      margin: 0;

      figcaption {
        display: none;
      }
    }
  }
}

.island-code {
  @include when-visible {
    padding: 0;
    border-radius: 0;

    .code-line-numbers {
      display: none;
    }
  }
}

@keyframes reveal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}