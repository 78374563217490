@use "sass:math";
@use "resources/styles/tokens" as tokens;

.details {
  margin-bottom: tokens.$size-spacing-md;
}

.details[open] .details-summary {
  &::before {
    transform: translateY(-50%) rotate(45deg);
  }
}

.details-summary {
  position: relative;

  display: block;
  padding: tokens.$size-spacing-sm 0 tokens.$size-spacing-sm #{tokens.$size-spacing-lg + tokens.$size-spacing-md};

  font-size: tokens.$size-text-lg;
  font-weight: tokens.$weight-text-bold;

  cursor: pointer;

  &:hover {
    &::after {
      transform: translateY(-50%) scale(1.1);
    }
  }

  &:active {
    &::after {
      transform: translateY(-50%);
      transition: transform tokens.$timing-instant;
    }
  }

  &::before {
    content: "";

    position: absolute;
    left: math.div(tokens.$size-spacing-lg - tokens.$size-spacing-md, 2);
    top: 50%;
    z-index: 1;

    display: block;
    height: 1em;
    width: 1em;

    background: {
      image: url("data-url:/resources/icons/plus.svg");
      position: center;
      size: 1em;
      repeat: no-repeat;
    }

    transform: translateY(-50%);
    transition: transform tokens.$timing-normal;
  }

  &::after {
    content: "";

    position: absolute;
    left: 0;
    top: 50%;

    display: block;
    height: tokens.$size-spacing-lg;
    width: tokens.$size-spacing-lg;

    background-color: var(--color-background);

    border-radius: tokens.$size-radius-xs;

    transform: translateY(-50%);
    transform-origin: center;
    transition: transform tokens.$timing-normal;
  }
}

.details-content {
  padding-left: #{tokens.$size-spacing-lg + tokens.$size-spacing-md};
}