.iziToast-capsule {
  font-size: 0;
  height: 0;
  width: 100%;
  transform: translateZ(0);
  backface-visibility: hidden;
  transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1), height 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.iziToast-overlay {
  display: block;
  position: fixed;
  top: -100px;
  left: 0;
  right: 0;
  bottom: -100px;
  z-index: 997;
}

.iziToast {
  display: inline-block;
  clear: both;
  position: relative;
  font-size: 1rem;
  padding: 8px 45px 9px 0;
  background: var(--color-background-inverted);
  overflow: hidden;
  width: 100%;
  pointer-events: all;
  cursor: default;
  transform: translateX(0);
  user-select: none;
}

.iziToast > .iziToast-progressbar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background: rgba(255,255,255,0.2);
}

.iziToast > .iziToast-progressbar > div {
  height: 2px;
  width: 100%;
  background: rgba(0,0,0,0.3);
  border-radius: 0 0 3px 3px;
}

.iziToast.iziToast-balloon:before {
  content: '';
  position: absolute;
  right: 8px;
  left: auto;
  width: 0;
  height: 0;
  top: 100%;
  border-right: 0 solid transparent;
  border-left: 15px solid transparent;
  border-top: 10px solid #fff;
  border-top-color: inherit;
  border-radius: 0;
}

.iziToast.iziToast-balloon .iziToast-progressbar {
  top: 0;
  bottom: auto;
}

.iziToast.iziToast-balloon > div {
  border-radius: 0 0 0 3px;
}

.iziToast > .iziToast-close {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  padding: 0;
  opacity: 0.6;
  width: 42px;
  height: 100%;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAJPAAACTwBcGfW0QAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAD3SURBVFiF1ZdtDoMgDEBfdi4PwAX8vLFn0qT7wxantojKupmQmCi8R4tSACpgjC2ICCUbEBa8ingjsU1AXRBeR8aLN64FiknswN8CYefBBDQ3whuFESy7WyQMeC0ipEI0A+0FeBvHUFN8xPaUhAH/iKoWsnXHGegy4J0yxialOfaHJAz4bhRzQzgDvdGnz4GbAonZbCQMuBm1K/kcFu8Mp1N2cFFpsxsMuJqqbIGExGl4loARajU1twskJLLhIsID7+tvUoDnIjTg5T9DPH9EBrz8rxjPzciAl9+O8SxI8CzJ8CxKFfh3ynK8Dyb8wNHM/XDqejx/AtNyPO87tNybAAAAAElFTkSuQmCC") no-repeat 50% 50%;
  background-size: 8px;
  cursor: pointer;
  outline: none;
  filter: invert(1);
}

.iziToast > .iziToast-close:hover {
  opacity: 1;
}

.iziToast > .iziToast-body {
  position: relative;
  padding: 0 0 0 10px;
  height: auto;
  min-height: 36px;
  margin: 0 0 0 15px;
  text-align: left;
}

.iziToast > .iziToast-body:after {
  content: "";
  display: table;
  clear: both;
}

.iziToast > .iziToast-body .iziToast-texts {
  margin: 10px 0 0 0;
  padding-right: 2px;
  display: inline-block;
  float: left;
}

.iziToast > .iziToast-body .iziToast-inputs {
  min-height: 19px;
  float: left;
  margin: 3px -2px;
}

.iziToast > .iziToast-body .iziToast-icon {
  position: absolute;
  left: 0;
  top: 50%;
  display: table;
  font-size: 23px;
  line-height: 24px;
  margin-top: -12px;
  color: var(--color-text-inverted);
  width: 24px;
  height: 24px;
}

.iziToast > .iziToast-body .iziToast-icon.ico-info {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAflBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACCtoPsAAAAKXRSTlMA6PsIvDob+OapavVhWRYPrIry2MxGQ97czsOzpJaMcE0qJQOwVtKjfxCVFeIAAAI3SURBVFjDlJPZsoIwEETnCiGyb8q+qmjl/3/wFmGKwjBROS9QWbtnOqDDGPq4MdMkSc0m7gcDDhF4NRdv8NoL4EcMpzoJglPl/KTDz4WW3IdvXEvxkfIKn7BMZb1bFK4yZFqghZ03jk0nG8N5NBwzx9xU5cxAg8fXi20/hDdC316lcA8o7t16eRuQvW1XGd2d2P8QSHQDDbdIII/9CR3lUF+lbucfJy4WfMS64EJPORnrZxtfc2pjJdnbuags3l04TTtJMXrdTph4Pyg4XAjugAJqMDf5Rf+oXx2/qi4u6nipakIi7CsgiuMSEF9IGKg8heQJKkxIfFSUU/egWSwNrS1fPDtLfon8sZOcYUQml1Qv9a3kfwsEUyJEMgFBKzdV8o3Iw9yAjg1jdLQCV4qbd3no8yD2GugaC3oMbF0NYHCpJYSDhNI5N2DAWB4F4z9Aj/04Cna/x7eVAQ17vRjQZPh+G/kddYv0h49yY4NWNDWMMOMUIRYvlTECmrN8pUAjo5RCMn8KoPmbJ/+Appgnk//Sy90GYBCGgm7IAskQ7D9hFKW4ApB1ei3FSYD9PjGAKygAV+ARFYBH5BsVgG9kkBSAQWKUFYBRZpkUgGVinRWAdUZQDABBQdIcAElDVBUAUUXWHQBZx1gMAGMprM0AsLbVXHsA5trZe93/wp3svQ0YNb/jWV3AIOLsMtlznSNOH7JqjOpDVh7z8qCZR10ftvO4nxeOvPLkpSuvfXnxzKtvXr7j+v8C5ii0e71At7cAAAAASUVORK5CYII=") no-repeat 50% 50%;
  background-size: 85%;
}

.iziToast > .iziToast-body .iziToast-icon.ico-warning {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAMAAAAPzWOAAAAAkFBMVEUAAAAAAAABAAIAAAABAAIAAAMAAAABAAIBAAIBAAIAAAIAAAABAAIAAAABAAICAAICAAIAAAIAAAAAAAAAAAABAAIBAAIAAAMAAAABAAIBAAMBAAECAAIAAAIAAAIAAAABAAIBAAIBAAMBAAIBAAEAAAIAAAMAAAAAAAABAAECAAICAAIAAAIAAAMAAAQAAAE05yNAAAAAL3RSTlMAB+kD7V8Q+PXicwv7I9iYhkAzJxnx01IV5cmnk2xmHfzexsK4eEw5L7Gei39aRw640awAAAHQSURBVFjD7ZfJdoJAEEWJgCiI4oDiPM8m7///LidErRO7sHrY5u7YXLr7vKqu9kTC0HPmo9n8cJbEQOzqqAdAUHeUZACQuTkGDQBoDJwkHZR0XBz9FkpafXuHP0SJ09mGeJLZ5wwlTmcbA0THPmdEK7XPGTG1zxmInn3OiJ19zkB0jSVTKExMHT0wjAwlWzC0fSPHF1gWRpIhWMYm7fYTFcQGlbemf4dFfdTGg0B/KXM8qBU/3wntbq7rSGqvJ9kla6IpueFJet8fxfem5yhykjyOgNaWF1qSGd5JMNNxpNF7SZQaVh5JzLrTCZIEJ1GyEyVyd+pClMjdaSJK5O40giSRu5PfFiVyd1pAksjdKRnrSsbVdbiHrgT7yss315fkVQPLFQrL+4FHeOXKO5YRFEKv5AiFaMlKLlBpJuVCJlC5sJfvCgztru/3NmBYccPgGTxRAzxn1XGEMUf58pXZvjoOsOCgjL08+b53mtfAM/SVsZcjKLtysQZPqIy9HPP3m/3zKItRwT0LyQo8sTr26tcO83DIUMWIJjierHLsJda/tbNBFY0BP/bKtcM8HNIWCK3aYR4OMzgxo5w5EFLOLKDExXAm9gI4E3iAO94/Ct/lKWuM2LMGbgAAAABJRU5ErkJggg==") no-repeat 50% 50%;
  background-size: 85%;
}

.iziToast > .iziToast-body .iziToast-icon.ico-error {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAeFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAVyEiIAAAAJ3RSTlMA3BsB98QV8uSyWVUFz7+kcWMM2LuZioBpTUVBNcq2qaibj4d1azLZZYABAAACZElEQVRYw7WX25KCMAyGAxUoFDkpiohnV97/DXeGBtoOUprZ2dyo1K82fxKbwJJVp+KQZ7so2mX5oThVQLKwjDe9YZu4DF3ptAn6rxY0qQPOEq9fNC9ha3y77a22ba24v+9Xbe8v8x03dPOC2/NdvB6xeSreLfGJpnx0TyotKqLm2s7Jd/WO6ivXNp0tCy02R/aFz5VQ5wUPlUL5fIfj5KIlVGU0nWHm/5QtoTVMWY8mzIVu1K9O7XH2JiU/xnOOT39gnUfj+lFHddx4tFjL3/H8jjzaFCy2Rf0c/fdQyQszI8BDR973IyMSKa4krjxAiW/lkRvMP+bKK9WbYS1ASQg8dKjaUGlYPwRe/WoIkz8tiQchH5QAEMv6T0k8MD4mUyWr4E7jAWqZ+xWcMIYkXvlwggJ3IvFK+wIOcpXAo8n8P0COAaXyKH4OsjBuZB4ew0IGu+H1SebhNazsQBbWm8yj+hFuUJB5eMsN0IUXmYendAFFfJB5uEkRMYwxmcd6zDGRtmQePEykAgubymMRFmMxCSIPCRbTuFNN5OGORTjmNGc0Po0m8Uv0gcCry6xUhR2QeLii9tofbEfhz/qvNti+OfPqNm2Mq6105FUMvdT4GPmufMiV8PqBMkc+DdT1bjYYbjzU/ew23VP4n3mLAz4n8Jtv/Ui3ceTT2mzz5o1mZt0gnBpmsdjqRqVlmplcPdqa7X23kL9brdm2t/uBYDPn2+tyu48mtIGD10JTuUrukVrbCFiwDzcHrPjxKt7PW+AZQyT/WESO+1WL7f3o+WLHL2dYMSZsg6dg/z360ofvP4//v1NPzgs28WlWAAAAAElFTkSuQmCC") no-repeat 50% 50%;
  background-size: 80%;
}

.iziToast > .iziToast-body .iziToast-icon.ico-success {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABABAMAAABYR2ztAAAAIVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABt0UjBAAAACnRSTlMApAPhIFn82wgGv8mVtwAAAKVJREFUSMft0LEJAkEARNFFFEw1NFJb8CKjAy1AEOzAxNw+bEEEg6nyFjbY4LOzcBwX7S/gwUxoTdIn+Jbv4Lv8bx446+kB6VsBtK0B+wbMCKxrwL33wOrVeeChX28n7KTOTjgoEu6DRSYAgAAAAkAmAIAAAAIACQIkMkACAAgAIACAyECBKAOJuCagTJwSUCaUAEMAABEBRwAAEQFLbCJgO4bW+AZKGnktR+jAFAAAAABJRU5ErkJggg==") no-repeat 50% 50%;
  background-size: 85%;
}

.iziToast > .iziToast-body .iziToast-icon.ico-question {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfhCQkUEhFovxTxAAAEDklEQVRo3s2ZTWgTQRTHf03ipTRUqghNSgsRjHgQrFUQC6JgD1Kak3gQUUoPqRdBglf1oBehBws9Cn4cGk+1SOmh2upBxAYVoeJHrR9tgq0i1Cq0lqYeks7MbpPdmU00/c8hm9n33v/t7Nt5M2+qMEWQI0QIibZKRrQpHvLL2KI2wnQzzBKrDm2RIeKEy01dTYKUI7G1ZRknQXV5yP10kTYgly1NF/5S6duZ8ES+1iZodyaocrjXxE0OFeifYYgp0mRIkwFChAkRJsIxGgrIP+I0n82fvZW5dc/zkss0O2o1c5mX6/TmaDWl77RFe5YkUW3tKEmyFv0lOvXJ/fTYnmCEFuMRbGHEZqVHLyT9DFjUJmkzJl9DG5MWWwM6Llif/gF1nukB6nhgGwUXdFrE+wiURA8QoM9i0zEWWpXQW+ZsyeRrOMuyEo5Fv4gmy4dXPvqcC+pH2VRYaMwy+OWG+iLGCgm0W0Kv9HdvR8ASjmKCXpuK/bxiV/76A/v5UdDIZuKcJGjrnec5KZ7wwsWFOp6xPX/9mt2sqDe7FO+Kf/fXHBPPDWpdXGhTpLvUG9VKwh1xMDDjkvu+cNDFBTk7ptX1QkKZ850m3duu6fcrWxwdaFFyREJ2j4vOpKP6Du6z4uJCv8sYJIVkCnJBGGZaBONO3roY2EqNrSfIPi7SKP4fdXyNUd6I6wbSAHEl33tFLe+FlSsusnK90A0+oEPcuufZgXnOi+u9LrKSJQZQw6LwqBnv2CKsfHORbFbyQhA6xN/pEuihSdj56Co7LWRjPiKie6gkB2LiKuUqK5kiPkLiz1QJ9K1cNXBAMoUCigNpQ9IqDtMI1HKA4/jyvUsaoSyZLA5kjOjDPFZen8Ql5TsvBskUgjciIPSX3QAXC86DT7VWvlEh/xZ+ij9BDVWJ0QL0SbZq6QaFxoLPcXPmBLveLCc4wXdDK6s+6/vwhCSniFLPXW0NJe5UB8zKCsviqpc7vGPVQFcyZbyPwGD+d5ZnxmNWlhG4xSBZZjivjIWHEQgoDkSMjMwTo54569JSE5IpA7EyJSMTyGTUAUFlO1ZKOtaHTMeL1PhYYFTcihmY2cQ5+ullj7EDkiVfVez2sCTz8yiv84djhg7IJVk81xFWJlPdfHBG0flkRC/zQFZ+DSllNtfDdUsOMCliyGX5uOzU3ZhIXFDof4m1gDuKbEx0t2YS25gVGpcMnr/I1kx3c6piB8P8ZoqEwfMX3ZyCXynJTmq/U7NUXqfUzCbWL1wqVKBQUeESzQYoUlW8TAcVL1RCxUu1G6BYXfFyfQ4VPbDI4T8d2WzgQ6sc/vmxnTsqfHCZQzUJxm1h5dxS5Tu6lQgTZ0ipqRVqSwzTbbLHMt+c19iO76tsx/cLZub+Ali+tYC93olEAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE3LTA5LTA5VDIwOjE4OjE3KzAyOjAwjKtfjgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNy0wOS0wOVQyMDoxODoxNyswMjowMP325zIAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC") no-repeat 50% 50%;
  background-size: 85%;
}

.iziToast > .iziToast-body .iziToast-title {
  padding: 0;
  margin: 0;
  line-height: 16px;
  font-size: 14px;
  text-align: left;
  float: left;
  color: var(--color-text-inverted);
  white-space: normal;
}

.iziToast > .iziToast-body .iziToast-message {
  padding: 0;
  margin: 0 0 10px 0;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  float: left;
  color: var(--color-text-inverted);
  white-space: normal;
}

.iziToast.iziToast-animateInside .iziToast-title,
.iziToast.iziToast-animateInside .iziToast-message,
.iziToast.iziToast-animateInside .iziToast-icon,
.iziToast.iziToast-animateInside .iziToast-buttons-child,
.iziToast.iziToast-animateInside .iziToast-inputs-child {
  opacity: 0;
}

.iziToast-target {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.iziToast-target .iziToast-capsule {
  overflow: hidden;
}

.iziToast-target .iziToast-capsule:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.iziToast-target .iziToast-capsule .iziToast {
  width: 100%;
  float: left;
}
.iziToast-wrapper {
  z-index: 99999;
  position: fixed;
  width: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
}
.iziToast-wrapper .iziToast.iziToast-balloon:before {
  border-right: 0 solid transparent;
  border-left: 15px solid transparent;
  border-top: 10px solid #000;
  border-top-color: inherit;
  right: 8px;
  left: auto;
}
.iziToast-wrapper-bottomLeft {
  left: 0;
  bottom: 0;
  text-align: left;
}
.iziToast-wrapper-bottomLeft .iziToast.iziToast-balloon:before {
  border-right: 15px solid transparent;
  border-left: 0 solid transparent;
  right: auto;
  left: 8px;
}
.iziToast-wrapper-bottomRight {
  right: 0;
  bottom: 0;
  text-align: right;
}
.iziToast-wrapper-topLeft {
  left: 0;
  top: 0;
  text-align: left;
}
.iziToast-wrapper-topLeft .iziToast.iziToast-balloon:before {
  border-right: 15px solid transparent;
  border-left: 0 solid transparent;
  right: auto;
  left: 8px;
}
.iziToast-wrapper-topRight {
  top: 0;
  right: 0;
  text-align: right;
}
.iziToast-wrapper-topCenter {
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
}
.iziToast-wrapper-bottomCenter {
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
.iziToast-wrapper-center {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  justify-content: center;
  flex-flow: column;
  align-items: center;
}

@media only screen and (min-width: 568px) {
  .iziToast-wrapper {
    padding: 10px 15px;
  }

  .iziToast {
    margin: 5px 0;
    border-radius: 0.5rem;
    width: auto;
  }

  .iziToast:not(.iziToast-rtl) .iziToast-cover {
    border-radius: 3px 0 0 3px;
  }

  .iziToast.iziToast-rtl .iziToast-cover {
    border-radius: 0 3px 3px 0;
  }

  .iziToast.iziToast-color-dark:after {
    box-shadow: inset 0 -10px 20px -10px rgba(255,255,255,0.3), 0 10px 10px -5px rgba(0,0,0,0.25);
  }

  .iziToast.iziToast-balloon .iziToast-progressbar {
    background: transparent;
  }

  .iziToast.iziToast-balloon:after {
    box-shadow: 0 10px 10px -5px rgba(0,0,0,0.25), inset 0 10px 20px -5px rgba(0,0,0,0.25);
  }

  .iziToast-target .iziToast:after {
    box-shadow: inset 0 -10px 20px -10px rgba(0,0,0,0.2), inset 0 0 5px rgba(0,0,0,0.1);
  }
}

.iziToast.fadeInUp {
  animation: iziT-fadeInUp 0.7s ease both;
}

.iziToast.fadeOut {
  animation: iziT-fadeOut 0.7s ease both;
}

.iziToast.fadeOutDown {
  animation: iziT-fadeOutDown 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both;
}

.iziToast-overlay.fadeOut {
  animation: iziT-fadeOut 0.7s ease both;
}

@keyframes iziT-fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes iziT-fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes iziT-fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.iziToast.slideIn,
.iziToast .slideIn {
  -webkit-animation: iziT-slideIn 1s cubic-bezier(0.16, 0.81, 0.32, 1) both;
  -moz-animation: iziT-slideIn 1s cubic-bezier(0.16, 0.81, 0.32, 1) both;
  animation: iziT-slideIn 1s cubic-bezier(0.16, 0.81, 0.32, 1) both;
}

@keyframes iziT-slideIn {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}