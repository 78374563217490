@use "../../tokens" as tokens;

.code {
  display: block;
  overflow: auto;
  padding: tokens.$size-spacing-md;
  background-color: var(--color-background);
  border-radius: tokens.$size-radius-sm;
  font-size: tokens.$size-text-sm;
  line-height: 1.4;

  &--line-numbers {
    display: flex;
  }
}

.code-line-numbers {
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  padding-right: tokens.$size-spacing-md;
  margin-right: tokens.$size-spacing-md;
  border-right: var(--color-border) 1px solid;
  line-height: 1.4;
  font-family: "Segoe UI Mono", "Ubuntu Mono", "Roboto Mono", Menlo, Monaco, Consolas, monospace;
  text-align: right;
  color: var(--color-text-muted);

  counter-reset: line-numbers;

  &  > span::after {
    content: counter(line-numbers);
    counter-increment: line-numbers;
  }
}