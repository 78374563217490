.token {
  &-emphasis {
    font-style: italic;
  }

  &-strong {
    font-weight: 700;
  }

  &-link {
    text-decoration: underline;
  }

  &-comment,
  &-quote {
    color: var(--color-token-green);
    font-style: italic;
  }

  &-regexp,
  &-selector-attr,
  &-selector-pseudo,
  &-variable.language_,
  &-deletion,
  &-keyword {
    color: var(--color-token-violet);
  }

  &-error {
    color: var(--color-token-red);
  }

  &-code,
  &-formula,
  &-property,
  &-section,
  &-subst {
    color: var(--color-token-blue);
  }

  &-char.escape_,
  &-meta,
  &-name,
  &-operator,
  &-selector-tag,
  &-addition,
  &-bullet,
  &-meta &-string,
  &-selector-class,
  &-string,
  &-symbol,
  &-title.class_,
  &-title.class_.inherited__ {
    color: var(--color-token-green);
  }

  &-attribute,
  &-built_in,
  &-doctag,
  &-link,
  &-literal,
  &-meta &-keyword,
  &-number,
  &-selector-id,
  &-tag,
  &-template-tag,
  &-template-variable,
  &-type,
  &-variable {
    color: var(--color-token-cyan);
  }
}