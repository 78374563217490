@charset "UTF-8";

@use "resources/styles/tokens" as tokens;

@import "~normalize-scss/sass/normalize/_import-now";

@import "variables";

@import "components/container";
@import "components/section";
@import "components/code";
@import "components/tippy";
@import "components/figure";
@import "components/equation";
@import "components/nav";
@import "components/details";
@import "components/active-block";
@import "components/toast";
@import "components/article-snippet";
@import "components/block-list";
@import "components/island";
@import "components/selection";
@import "components/banner";
@import "components/footer";
@import "components/button";

@import "fonts/source-sans-3";
@import "fonts/jetbrains-mono";

@view-transition {
  navigation: auto;
}

::view-transition-old(root) {
  animation: tokens.$timing-slowed view-out tokens.$timing-instant ease;
}

::view-transition-new(root) {
  animation: tokens.$timing-slowed view-in tokens.$timing-instant ease;
}

@keyframes view-out {
  from {
    translate: 0;
  }

  to {
    translate: 0 -30vh;
  }
}

@keyframes view-in {
  from {
    translate: 0 100vh;
  }

  to {
    translate: 0;
  }
}

*, *::before, *::after {
  box-sizing: border-box;
}

:root {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  color: var(--color-text);
  background-color: var(--color-background);
  font-family: tokens.$font-family-sans-serif;
  font-size: var(--size-text-m);
  line-height: tokens.$line-height-default;
}

h1 {
  margin-block: tokens.$size-spacing-xl;
  line-height: tokens.$line-height-sm;
}

h2 {
  margin-block: tokens.$size-spacing-xl tokens.$size-spacing-md;
  line-height: tokens.$line-height-sm;
}

h1, h2, h3, h4, h5, h6, strong, b, th {
  font-weight: tokens.$weight-text-bold;
}

h1, h2, h3, h4, h5, h6 {
  scroll-margin-top: tokens.$size-spacing-lg;
  hyphens: auto;
  overflow-wrap: break-word;
}

@supports not (scroll-margin-top: 0) {
  h1, h2, h3, h4, h5, h6 {
    scroll-snap-margin-top: tokens.$size-spacing-lg;
  }
}

pre {
  margin: 0;
}

pre > code {
  padding: 0;
}

code, kbd, samp {
  background-color: var(--color-background);
  font-size: tokens.$size-text-sm;
  font-family: tokens.$font-family-monospace;
}

code {
  padding: 0 0.5ex;
  border-radius: 0.5ex;
}

ol, ul {
  padding-inline-start: tokens.$size-spacing-lg;

  & > li {
    padding-inline-start: tokens.$size-spacing-md;
  }
}

ul > li::marker {
  content: "✦";
}

.limit-width {
  max-width: tokens.$size-content-max;
}

.table-wrapper {
  margin: 2rem 0;
  position: relative;
}

.table {
  $border: #cecece 1px solid;

  border-collapse: separate;
  table-layout: auto;
  border-spacing: 0;
  caption-side: bottom;

  margin: 2em 0;

  th:first-of-type {
    border-top-left-radius: 0.5em;
  }

  th:last-of-type {
    border-top-right-radius: 0.5em;
  }

  tbody > tr:last-child {
    td:first-of-type {
      border-bottom-left-radius: 0.5em;
    }

    td:last-of-type {
      border-bottom-right-radius: 0.5em;
    }
  }

  th {
    border-top: $border;
    text-align: left;
  }

  td, th {
    border-bottom: $border;
    border-left: $border;
    padding: 0.25em 0.5em;

    &:last-child {
      border-right: $border;
    }
  }

  caption {
    font-size: var(--size-text-s);
    text-align: left;
    margin-top: 0.25em;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}