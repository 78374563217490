@mixin jetbrains-mono($name, $weight, $style) {
  @font-face {
    font-family: "JetBrains Mono";
    font-style: $style;
    font-weight: $weight;
    font-display: optional;

    src: url("/resources/fonts/jetbrains-mono/#{$name}.woff2") format("woff2");
  }
}

@include jetbrains-mono(Regular, 400, normal);
@include jetbrains-mono(Italic, 400, italic);