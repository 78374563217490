.active-block-action {
  display: block;
  height: 3.2rem;
  width:  3.2rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;

  background: {
    position: center center;
    repeat: no-repeat;
    size: 1.6rem;
    color: transparent;
  }

  transition: background-color var(--timing-normal) ease;

  &:hover {
    background-color: rgba(0, 0, 0, .05);
  }

  &:active {
    background-color: transparent;
    transition: background-color var(--timing-instant) ease;
  }

  @media screen and (pointer: coarse) {
    height: 4.2rem;
    width: 4.2rem;
    background-size: 2.4rem;;
  }
}

.active-block-action--icon-copy {
  background-image: url("data-url:/resources/icons/copy.svg");
}

.active-block-action--icon-play {
  background-image: url("data-url:/resources/icons/play.svg");
}

.active-block-action--icon-link {
  background-image: url("data-url:/resources/icons/link.svg");
}