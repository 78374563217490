@use "resources/styles/tokens" as tokens;

.article-snippet {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: tokens.$size-spacing-sm;

  padding: tokens.$size-spacing-md;

  border-radius: tokens.$size-radius-sm;

  background: var(--color-background-section);
}

.article-snippet-title {
  margin: 0;

  display: block;
}

.article-snippet-link {
  font-size: tokens.$size-text-lg;
  font-weight: tokens.$weight-text-bold;

  color: var(--color-text);
  text-decoration: underline transparent;
  text-underline-offset: 0.5ex;

  &:hover {
    text-decoration-color: var(--color-text);
    transition: text-decoration-color tokens.$timing-normal;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
  }
}