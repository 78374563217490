@use "resources/styles/tokens" as tokens;

.section {
  max-width: var(--max-width-section);
  border-radius: 4.8rem;
  margin: 0 auto;
  padding: 4.8rem 7.2rem;
  position: relative;

  @media screen and (width <= 1600px) {
    margin: 0 auto 0 0;
  }

  @media screen and (width < 1408px) {
    padding: 4.8rem 7.2rem;
  }

  @media screen and (width <= 1025px) {
    margin: 0 auto;
  }

  @media screen and (width <= 600px) {
    padding: 1.5rem 1rem;
  }
}

.section_filled {
  background-color: var(--color-background-section);
}
