@use "resources/styles/tokens" as tokens;

.nav {
  padding-block: tokens.$size-spacing-lg;

  @media (width <= tokens.$breakpoint-width-md) {
    padding-inline: tokens.$size-spacing-sm;
  }

  @media (width <= tokens.$breakpoint-width-sm) {
    position: fixed;
    inset-block: auto 0;
    inset-inline: 0;
    z-index: 10;

    padding-block: 0;
    padding-inline: tokens.$size-spacing-md;

    background-color: var(--color-background-inverted);
  }
}

.nav-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (width <= tokens.$breakpoint-width-sm) {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

.nav-item {
  border: none;
  border-radius: tokens.$size-radius-xs;
  padding: tokens.$size-spacing-md;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5ex;

  text-decoration: none;

  color: var(--color-text);
  background-color: transparent;

  cursor: pointer;

  transition: background-color tokens.$timing-normal ease;

  &:hover {
    background-color: rgba(0, 0, 0, .05);
  }

  &:active {
    background-color: transparent;
    transition-duration: tokens.$timing-instant;
  }

  @media screen and (width <= tokens.$breakpoint-width-sm) {
    gap: 0;
    flex-direction: column;

    font-size: tokens.$size-text-xs;
    line-height: 1;

    color: var(--color-text-inverted);
  }

  > svg {
    height: 1em;
    width: 1em;

    stroke: currentColor;

    @media screen and (width <= tokens.$breakpoint-width-sm) {
      height: 2em;
      width: 2em;
    }
  }
}

#back-to-top {
  position: fixed;
  inset-block: tokens.$size-spacing-lg auto;
  inset-inline: auto tokens.$size-spacing-lg;

  font-size: var(--size-text-s);

  &[hidden] {
    opacity: 0;
  }

  //noinspection CssInvalidAtRule
  @starting-style {
    opacity: 0;
  }

  transition: opacity tokens.$timing-normal, display tokens.$timing-normal allow-discrete;

  @media screen and (width <= tokens.$breakpoint-width-md) {
    display: none;
  }
}

#menu-toggle {
  anchor-name: --main-menu;

  path {
    transform-origin: center;
    transition: transform var(--timing-normal) ease;
  }
}

.dropdown-list {
  &:popover-open, &.\:popover-open {
    & + #menu-toggle {
      background-color: var(--color-background-inverted);
      color: var(--color-text-inverted);

      transition-duration: var(--timing-instant);

      path:first-of-type {
        transform: translate(12%, -12%) rotate(45deg);
      }

      path:last-of-type {
        transform: translate(12%, 6%) rotate(-45deg);
      }
    }
  }
}

//noinspection CssInvalidFunction
.dropdown-list {
  border: none;
  padding: tokens.$size-spacing-md;
  border-radius: tokens.$size-radius-xs;

  color: var(--color-text-inverted);
  background-color: var(--color-background-inverted);

  list-style: none;
  box-shadow: tokens.$shadow-md;

  @supports (position-anchor: --main-menu) {
    margin-block: tokens.$size-spacing-sm 0;
    margin-inline: 0;

    position-anchor: --main-menu;
    inset-area: block-end span-inline-end;

    @media screen and (width <= tokens.$breakpoint-width-sm) {
      inset-inline: 0;
      inset-block: auto anchor(top);
      inset-area: none;
    }
  }

  &:popover-open, &.\:popover-open {
    display: flex;
    gap: tokens.$size-spacing-md;
  }

  a {
    color: var(--color-text-inverted);
    text-decoration: none;

    &:hover {
      text-decoration: underline wavy;
      text-underline-offset: 0.5ex;
    }
  }

  & > li {
    padding: 0;
  }

  @media screen and (width <= tokens.$breakpoint-width-sm) {
    width: 100%;
    justify-content: space-between;
    box-shadow: none;
    font-size: tokens.$size-text-lg;

    @supports (position-anchor: --main-menu) {
      border-start-start-radius: tokens.$size-radius-md;
      border-start-end-radius: tokens.$size-radius-md;
      border-end-start-radius: 0;
      border-end-end-radius: 0;

      padding-block-start: tokens.$size-spacing-lg;

      border-block-end: 1px solid var(--color-border-inverted);
    }

    @supports not (position-anchor: --main-menu) {
      flex-direction: column;
      margin-inline: tokens.$size-spacing-sm;
      width: calc(100% - #{tokens.$size-spacing-sm * 2});
    }
  }
}