.active-block {
  position: relative;

  .active-block__action-group {
    opacity: 0;
    transition: opacity var(--timing-normal) ease;

    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    position: absolute;
    top: 0;
    left: -4.8rem;
    padding: 1rem calc(4rem - 3.2rem) 1rem 0;
    
    @media screen and (pointer: coarse) {
      left: -5.8rem;
    }

    @media screen and (width <= 600px) {
      display: none;
    }
  }

  .active-block__action-group--position-center {
    @media screen and (width > 600px) {
      top: 50%;
      transform: translateY(-50%);
      padding: 0 calc(4rem - 3.2rem) 0 0;
    }
  }

  &:hover .active-block__action-group {
    opacity: 1;
  }
}