@use "resources/styles/tokens" as tokens;

.button {
  display: flex;
  width: fit-content;
  text-decoration: none;
  padding: tokens.$size-spacing-md;

  font-weight: tokens.$weight-text-bold;

  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: tokens.$size-radius-xs;
    z-index: -1;
    transition: transform tokens.$timing-normal;
  }

  &:hover {
    &::before {
      transform: scale(1.1);
    }
  }
}

.button_inverted {
  color: var(--color-text);

  &::before {
    background-color: var(--color-background-section);
  }
}