@use "../tokens" as tokens;

.selection {
  border-radius: tokens.$size-radius-mc;
}

.selection--code {
  background: rgba(0, 178, 255, 0.2);
}

.selection--code-error {
  text-decoration-color: var(--color-token-red);
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-skip-ink: none;
  text-underline-position: from-font;
  box-decoration-break: clone;
}