@use "resources/styles/tokens" as tokens;

.banner {
  padding: tokens.$size-spacing-lg;

  background-color: var(--color-light-blue);
  border-radius: tokens.$size-radius-sm;

  display: flex;
  gap: tokens.$size-spacing-md;

  @media screen and (width < tokens.$breakpoint-width-sm) {
    padding: tokens.$size-spacing-lg tokens.$size-spacing-md;
  }
}

.banner-content {
  display: flex;
  flex-direction: column;
}

.banner-title {
  margin: 0;

  font-size: tokens.$size-text-lg;
}

.banner-text {
  margin: 0;
  margin-block-start: tokens.$size-spacing-sm;
}

.banner-action {
  margin-block-start: tokens.$size-spacing-md;
}

.banner-image {
  height: 150px;
  width: auto;

  @media (width <= tokens.$breakpoint-width-sm) {
    display: none;
  }
}