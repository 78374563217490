html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

main {
  display: block;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

input {
  overflow: visible;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

:root {
  --color-text: #3a3a3a;
  --color-text-inverted: #fff;
  --color-text-muted: #8f8f8f;
  --color-background: #f5f5f5;
  --color-background-inverted: #3a3a3a;
  --color-background-section: #fff;
  --color-border: #ebebeb;
  --color-border-inverted: #555;
  --color-backdrop: #000000b3;
  --color-token-violet: #9b0064;
  --color-token-red: #a40000;
  --color-token-blue: #2f40c2;
  --color-token-cyan: #005073;
  --color-token-green: #006915;
  --color-token-gray: #3a3a3a;
  --color-light-blue: #ddedff;
  --size-text-s: 1.4rem;
  --size-text-m: 1.6rem;
  --line-height-text-m: 1.5;
  --line-height-text-s: 1.5;
  --max-width-section: 850px;
  --viewport-width-sm: 600px;
  --timing-instant: 0s;
  --timing-normal: .3s;
  --timing-slowed: .5s;
}

@supports (color: lab(0% 0 0)) {
  :root {
    --color-text: lab(24.6% 0 0);
    --color-text-inverted: lab(100% 0 0);
    --color-text-muted: lab(59.4% -.0000298023 .0000119209);
    --color-background: lab(96.52% -.0000298023 0);
    --color-background-inverted: lab(24.6% 0 0);
    --color-background-section: lab(100% 0 0);
    --color-border: lab(93.04% .0000298023 -.0000119209);
    --color-border-inverted: lab(36.2% 0 0);
    --color-backdrop: lab(0% 0 0 / .7);
    --color-token-violet: lab(33.4776% 62.5139 -12.2656);
    --color-token-red: lab(34.2094% 62.7575 58.5499);
    --color-token-blue: lab(33.1744% 30.0628 -70.8453);
    --color-token-cyan: lab(30.0311% -14.2633 -53.9889);
    --color-token-green: lab(37.9407% -42.3641 37.5694);
    --color-token-gray: lab(24.6% 0 0);
  }
}

.container {
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.container.is-fluid {
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  max-width: none !important;
}

@media screen and (width >= 1024px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (width <= 1215px) {
  .container.is-widescreen:not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (width <= 1407px) {
  .container.is-fullhd:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

@media screen and (width >= 1216px) {
  .container:not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (width >= 1408px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

@media screen and (width <= 599.999px) {
  .container {
    margin-bottom: 7.2rem;
  }
}

.container--fluid {
  width: 100%;
  max-width: none;
  padding-left: .8rem;
  padding-right: .8rem;
}

.section {
  max-width: var(--max-width-section);
  border-radius: 4.8rem;
  margin: 0 auto;
  padding: 4.8rem 7.2rem;
  position: relative;
}

@media screen and (width <= 1600px) {
  .section {
    margin: 0 auto 0 0;
  }
}

@media screen and (width <= 1408px) {
  .section {
    padding: 4.8rem 7.2rem;
  }
}

@media screen and (width <= 1025px) {
  .section {
    margin: 0 auto;
  }
}

@media screen and (width <= 600px) {
  .section {
    padding: 1.5rem 1rem;
  }
}

.section_filled {
  background-color: var(--color-background-section);
}

.code {
  background-color: var(--color-background);
  border-radius: 1.6rem;
  padding: 1.6rem;
  font-size: 1.4rem;
  line-height: 1.4;
  display: block;
  overflow: auto;
}

.code--line-numbers {
  display: flex;
}

.code-line-numbers {
  border-right: var(--color-border) 1px solid;
  text-align: right;
  color: var(--color-text-muted);
  counter-reset: line-numbers;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 1.6rem;
  padding-right: 1.6rem;
  font-family: Segoe UI Mono, Ubuntu Mono, Roboto Mono, Menlo, Monaco, Consolas, monospace;
  line-height: 1.4;
  display: flex;
}

.code-line-numbers > span:after {
  content: counter(line-numbers);
  counter-increment: line-numbers;
}

.token-emphasis {
  font-style: italic;
}

.token-strong {
  font-weight: 700;
}

.token-link {
  text-decoration: underline;
}

.token-comment, .token-quote {
  color: var(--color-token-green);
  font-style: italic;
}

.token-regexp, .token-selector-attr, .token-selector-pseudo, .token-variable.language_, .token-deletion, .token-keyword {
  color: var(--color-token-violet);
}

.token-error {
  color: var(--color-token-red);
}

.token-code, .token-formula, .token-property, .token-section, .token-subst {
  color: var(--color-token-blue);
}

.token-char.escape_, .token-meta, .token-name, .token-operator, .token-selector-tag, .token-addition, .token-bullet, .token-meta .token-string, .token-selector-class, .token-string, .token-symbol, .token-title.class_, .token-title.class_.inherited__ {
  color: var(--color-token-green);
}

.token-attribute, .token-built_in, .token-doctag, .token-link, .token-literal, .token-meta .token-keyword, .token-number, .token-selector-id, .token-tag, .token-template-tag, .token-template-variable, .token-type, .token-variable {
  color: var(--color-token-cyan);
}

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  background-color: var(--color-background-inverted);
  color: var(--color-text-inverted);
  font-size: var(--size-text-s);
  white-space: normal;
  border-radius: .5rem;
  outline: 0;
  line-height: 1.4;
  transition-property: transform, visibility, opacity;
  position: relative;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: top;
  bottom: -7px;
  left: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: bottom;
  top: -7px;
  left: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  transform-origin: 0;
  right: -7px;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: 100%;
  left: -7px;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-arrow {
  color: var(--color-background-inverted);
  width: 16px;
  height: 16px;
}

.tippy-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tippy-content {
  z-index: 1;
  padding: 5px 9px;
  position: relative;
}

.figure {
  margin: 2em 0;
  padding: 0;
  position: relative;
}

.figure img {
  width: 100%;
  height: auto;
}

.figure figcaption {
  font-size: var(--size-text-s);
  text-align: left;
  margin-top: .25em;
}

.equation {
  margin: 2em 0;
  overflow: auto hidden;
}

.nav {
  padding-block: 3.2rem;
}

@media (width <= 1024px) {
  .nav {
    padding-inline: .8rem;
  }
}

@media (width <= 600px) {
  .nav {
    z-index: 10;
    background-color: var(--color-background-inverted);
    padding-block: 0;
    padding-inline: 1.6rem;
    position: fixed;
    inset: auto 0 0;
  }
}

.nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

@media (width <= 600px) {
  .nav-list {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

.nav-item {
  color: var(--color-text);
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-radius: .8rem;
  flex-direction: row;
  align-items: center;
  gap: .5ex;
  padding: 1.6rem;
  text-decoration: none;
  transition: background-color .3s;
  display: flex;
}

.nav-item:hover {
  background-color: #0000000d;
}

.nav-item:active {
  background-color: #0000;
  transition-duration: 0s;
}

@media screen and (width <= 600px) {
  .nav-item {
    color: var(--color-text-inverted);
    flex-direction: column;
    gap: 0;
    font-size: 1.2rem;
    line-height: 1;
  }
}

.nav-item > svg {
  stroke: currentColor;
  width: 1em;
  height: 1em;
}

@media screen and (width <= 600px) {
  .nav-item > svg {
    width: 2em;
    height: 2em;
  }
}

#back-to-top {
  font-size: var(--size-text-s);
  transition: opacity .3s, display .3s allow-discrete;
  position: fixed;
  top: 3.2rem;
  bottom: auto;
}

#back-to-top:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: auto;
  right: 3.2rem;
}

#back-to-top:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 3.2rem;
  right: auto;
}

#back-to-top:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 3.2rem;
  right: auto;
}

#back-to-top[hidden] {
  opacity: 0;
}

@starting-style {
  #back-to-top {
    opacity: 0;
  }
}

@media screen and (width <= 1024px) {
  #back-to-top {
    display: none;
  }
}

#menu-toggle {
  anchor-name: --main-menu;
}

#menu-toggle path {
  transform-origin: center;
  transition: transform var(--timing-normal) ease;
}

.dropdown-list.\:popover-open + #menu-toggle {
  background-color: var(--color-background-inverted);
  color: var(--color-text-inverted);
  transition-duration: var(--timing-instant);
}

.dropdown-list:popover-open + #menu-toggle {
  background-color: var(--color-background-inverted);
  color: var(--color-text-inverted);
  transition-duration: var(--timing-instant);
}

.dropdown-list.\:popover-open + #menu-toggle path:first-of-type {
  transform: translate(12%, -12%)rotate(45deg);
}

.dropdown-list:popover-open + #menu-toggle path:first-of-type {
  transform: translate(12%, -12%)rotate(45deg);
}

.dropdown-list.\:popover-open + #menu-toggle path:last-of-type {
  transform: translate(12%, 6%)rotate(-45deg);
}

.dropdown-list:popover-open + #menu-toggle path:last-of-type {
  transform: translate(12%, 6%)rotate(-45deg);
}

.dropdown-list {
  color: var(--color-text-inverted);
  background-color: var(--color-background-inverted);
  border: none;
  border-radius: .8rem;
  padding: 1.6rem;
  list-style: none;
  box-shadow: 0 3px 3px -2px #0003, 0 3px 4px #00000024, 0 1px 8px #0000001f;
}

@supports (position-anchor: --main-menu) {
  .dropdown-list {
    position-anchor: --main-menu;
    inset-area: block-end span-inline-end;
    margin-block: .8rem 0;
    margin-inline: 0;
  }

  @media screen and (width <= 600px) {
    .dropdown-list {
      left: 0;
      right: 0;
      inset-block: auto anchor(top);
      inset-area: none;
    }
  }
}

.dropdown-list.\:popover-open {
  gap: 1.6rem;
  display: flex;
}

.dropdown-list:popover-open {
  gap: 1.6rem;
  display: flex;
}

.dropdown-list a {
  color: var(--color-text-inverted);
  text-decoration: none;
}

.dropdown-list a:hover {
  text-underline-offset: .5ex;
  text-decoration: underline wavy;
}

.dropdown-list > li {
  padding: 0;
}

@media screen and (width <= 600px) {
  .dropdown-list {
    box-shadow: none;
    justify-content: space-between;
    width: 100%;
    font-size: 1.8rem;
  }

  @supports (position-anchor: --main-menu) {
    .dropdown-list {
      border-block-end: 1px solid var(--color-border-inverted);
      padding-block-start: 3.2rem;
    }

    .dropdown-list:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
      border-radius: 3.2rem 3.2rem 0 0;
    }

    .dropdown-list:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
      border-radius: 3.2rem 3.2rem 0 0;
    }

    .dropdown-list:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
      border-radius: 3.2rem 3.2rem 0 0;
    }
  }

  @supports not (position-anchor: --main-menu) {
    .dropdown-list {
      flex-direction: column;
      width: calc(100% - 1.6rem);
      margin-inline: .8rem;
    }
  }
}

.details {
  margin-bottom: 1.6rem;
}

.details[open] .details-summary:before {
  transform: translateY(-50%)rotate(45deg);
}

.details-summary {
  cursor: pointer;
  padding: .8rem 0 .8rem 4.8rem;
  font-size: 1.8rem;
  font-weight: 650;
  display: block;
  position: relative;
}

.details-summary:hover:after {
  transform: translateY(-50%)scale(1.1);
}

.details-summary:active:after {
  transition: transform;
  transform: translateY(-50%);
}

.details-summary:before {
  content: "";
  z-index: 1;
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M12%2021V3M3%2012h18%22%20stroke%3D%22%233a3a3a%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1em;
  width: 1em;
  height: 1em;
  transition: transform .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: .8rem;
  transform: translateY(-50%);
}

.details-summary:after {
  content: "";
  background-color: var(--color-background);
  transform-origin: center;
  border-radius: .8rem;
  width: 3.2rem;
  height: 3.2rem;
  transition: transform .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.details-content {
  padding-left: 4.8rem;
}

.active-block {
  position: relative;
}

.active-block .active-block__action-group {
  opacity: 0;
  transition: opacity var(--timing-normal) ease;
  flex-direction: column;
  gap: 1.2rem;
  padding: 1rem .8rem 1rem 0;
  display: flex;
  position: absolute;
  top: 0;
  left: -4.8rem;
}

@media screen and (pointer: coarse) {
  .active-block .active-block__action-group {
    left: -5.8rem;
  }
}

@media screen and (width <= 600px) {
  .active-block .active-block__action-group {
    display: none;
  }
}

@media screen and (width >= 600.001px) {
  .active-block .active-block__action-group--position-center {
    padding: 0 .8rem 0 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.active-block:hover .active-block__action-group {
  opacity: 1;
}

.active-block-action {
  cursor: pointer;
  transition: background-color var(--timing-normal) ease;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.6rem;
  border: none;
  border-radius: .5rem;
  width: 3.2rem;
  height: 3.2rem;
  display: block;
}

.active-block-action:hover {
  background-color: #0000000d;
}

.active-block-action:active {
  transition: background-color var(--timing-instant) ease;
  background-color: #0000;
}

@media screen and (pointer: coarse) {
  .active-block-action {
    background-size: 2.4rem;
    width: 4.2rem;
    height: 4.2rem;
  }
}

.active-block-action--icon-copy {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20stroke%3D%22%233a3a3a%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Crect%20x%3D%229%22%20y%3D%229%22%20width%3D%2213%22%20height%3D%2213%22%20rx%3D%222%22%20ry%3D%222%22%2F%3E%3Cpath%20d%3D%22M5%2015H4a2%202%200%200%201-2-2V4a2%202%200%200%201%202-2h9a2%202%200%200%201%202%202v1%22%2F%3E%3C%2Fsvg%3E");
}

.active-block-action--icon-play {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20stroke%3D%22%233a3a3a%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22m5%203%2014%209-14%209V3z%22%2F%3E%3C%2Fsvg%3E");
}

.active-block-action--icon-link {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22none%22%20stroke%3D%22%233a3a3a%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M10%2013a5%205%200%200%200%207.54.54l3-3a5%205%200%200%200-7.07-7.07l-1.72%201.71%22%2F%3E%3Cpath%20d%3D%22M14%2011a5%205%200%200%200-7.54-.54l-3%203a5%205%200%200%200%207.07%207.07l1.71-1.71%22%2F%3E%3C%2Fsvg%3E");
}

.iziToast-capsule {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  height: 0;
  font-size: 0;
  transition: transform .5s cubic-bezier(.25, .8, .25, 1), height .5s cubic-bezier(.25, .8, .25, 1);
  transform: translateZ(0);
}

.iziToast-overlay {
  z-index: 997;
  display: block;
  position: fixed;
  inset: -100px 0;
}

.iziToast {
  clear: both;
  background: var(--color-background-inverted);
  pointer-events: all;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  padding: 8px 45px 9px 0;
  font-size: 1rem;
  display: inline-block;
  position: relative;
  overflow: hidden;
  transform: translateX(0);
}

.iziToast > .iziToast-progressbar {
  z-index: 1;
  background: #fff3;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.iziToast > .iziToast-progressbar > div {
  background: #0000004d;
  border-radius: 0 0 3px 3px;
  width: 100%;
  height: 2px;
}

.iziToast.iziToast-balloon:before {
  content: "";
  border-top: 10px solid #fff;
  border-left: 15px solid #0000;
  border-right: 0 solid #0000;
  border-top-color: inherit;
  border-radius: 0;
  width: 0;
  height: 0;
  position: absolute;
  top: 100%;
  left: auto;
  right: 8px;
}

.iziToast.iziToast-balloon .iziToast-progressbar {
  top: 0;
  bottom: auto;
}

.iziToast.iziToast-balloon > div {
  border-radius: 0 0 0 3px;
}

.iziToast > .iziToast-close {
  opacity: .6;
  cursor: pointer;
  filter: invert();
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAJPAAACTwBcGfW0QAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAD3SURBVFiF1ZdtDoMgDEBfdi4PwAX8vLFn0qT7wxantojKupmQmCi8R4tSACpgjC2ICCUbEBa8ingjsU1AXRBeR8aLN64FiknswN8CYefBBDQ3whuFESy7WyQMeC0ipEI0A+0FeBvHUFN8xPaUhAH/iKoWsnXHGegy4J0yxialOfaHJAz4bhRzQzgDvdGnz4GbAonZbCQMuBm1K/kcFu8Mp1N2cFFpsxsMuJqqbIGExGl4loARajU1twskJLLhIsID7+tvUoDnIjTg5T9DPH9EBrz8rxjPzciAl9+O8SxI8CzJ8CxKFfh3ynK8Dyb8wNHM/XDqejx/AtNyPO87tNybAAAAAElFTkSuQmCC") 50% / 8px no-repeat;
  border: 0;
  outline: none;
  width: 42px;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.iziToast > .iziToast-close:hover {
  opacity: 1;
}

.iziToast > .iziToast-body {
  text-align: left;
  height: auto;
  min-height: 36px;
  margin: 0 0 0 15px;
  padding: 0 0 0 10px;
  position: relative;
}

.iziToast > .iziToast-body:after {
  content: "";
  clear: both;
  display: table;
}

.iziToast > .iziToast-body .iziToast-texts {
  float: left;
  margin: 10px 0 0;
  padding-right: 2px;
  display: inline-block;
}

.iziToast > .iziToast-body .iziToast-inputs {
  float: left;
  min-height: 19px;
  margin: 3px -2px;
}

.iziToast > .iziToast-body .iziToast-icon {
  color: var(--color-text-inverted);
  width: 24px;
  height: 24px;
  margin-top: -12px;
  font-size: 23px;
  line-height: 24px;
  display: table;
  position: absolute;
  top: 50%;
  left: 0;
}

.iziToast > .iziToast-body .iziToast-icon.ico-info {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAflBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACCtoPsAAAAKXRSTlMA6PsIvDob+OapavVhWRYPrIry2MxGQ97czsOzpJaMcE0qJQOwVtKjfxCVFeIAAAI3SURBVFjDlJPZsoIwEETnCiGyb8q+qmjl/3/wFmGKwjBROS9QWbtnOqDDGPq4MdMkSc0m7gcDDhF4NRdv8NoL4EcMpzoJglPl/KTDz4WW3IdvXEvxkfIKn7BMZb1bFK4yZFqghZ03jk0nG8N5NBwzx9xU5cxAg8fXi20/hDdC316lcA8o7t16eRuQvW1XGd2d2P8QSHQDDbdIII/9CR3lUF+lbucfJy4WfMS64EJPORnrZxtfc2pjJdnbuags3l04TTtJMXrdTph4Pyg4XAjugAJqMDf5Rf+oXx2/qi4u6nipakIi7CsgiuMSEF9IGKg8heQJKkxIfFSUU/egWSwNrS1fPDtLfon8sZOcYUQml1Qv9a3kfwsEUyJEMgFBKzdV8o3Iw9yAjg1jdLQCV4qbd3no8yD2GugaC3oMbF0NYHCpJYSDhNI5N2DAWB4F4z9Aj/04Cna/x7eVAQ17vRjQZPh+G/kddYv0h49yY4NWNDWMMOMUIRYvlTECmrN8pUAjo5RCMn8KoPmbJ/+Appgnk//Sy90GYBCGgm7IAskQ7D9hFKW4ApB1ei3FSYD9PjGAKygAV+ARFYBH5BsVgG9kkBSAQWKUFYBRZpkUgGVinRWAdUZQDABBQdIcAElDVBUAUUXWHQBZx1gMAGMprM0AsLbVXHsA5trZe93/wp3svQ0YNb/jWV3AIOLsMtlznSNOH7JqjOpDVh7z8qCZR10ftvO4nxeOvPLkpSuvfXnxzKtvXr7j+v8C5ii0e71At7cAAAAASUVORK5CYII=") 50% / 85% no-repeat;
}

.iziToast > .iziToast-body .iziToast-icon.ico-warning {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAMAAAAPzWOAAAAAkFBMVEUAAAAAAAABAAIAAAABAAIAAAMAAAABAAIBAAIBAAIAAAIAAAABAAIAAAABAAICAAICAAIAAAIAAAAAAAAAAAABAAIBAAIAAAMAAAABAAIBAAMBAAECAAIAAAIAAAIAAAABAAIBAAIBAAMBAAIBAAEAAAIAAAMAAAAAAAABAAECAAICAAIAAAIAAAMAAAQAAAE05yNAAAAAL3RSTlMAB+kD7V8Q+PXicwv7I9iYhkAzJxnx01IV5cmnk2xmHfzexsK4eEw5L7Gei39aRw640awAAAHQSURBVFjD7ZfJdoJAEEWJgCiI4oDiPM8m7///LidErRO7sHrY5u7YXLr7vKqu9kTC0HPmo9n8cJbEQOzqqAdAUHeUZACQuTkGDQBoDJwkHZR0XBz9FkpafXuHP0SJ09mGeJLZ5wwlTmcbA0THPmdEK7XPGTG1zxmInn3OiJ19zkB0jSVTKExMHT0wjAwlWzC0fSPHF1gWRpIhWMYm7fYTFcQGlbemf4dFfdTGg0B/KXM8qBU/3wntbq7rSGqvJ9kla6IpueFJet8fxfem5yhykjyOgNaWF1qSGd5JMNNxpNF7SZQaVh5JzLrTCZIEJ1GyEyVyd+pClMjdaSJK5O40giSRu5PfFiVyd1pAksjdKRnrSsbVdbiHrgT7yss315fkVQPLFQrL+4FHeOXKO5YRFEKv5AiFaMlKLlBpJuVCJlC5sJfvCgztru/3NmBYccPgGTxRAzxn1XGEMUf58pXZvjoOsOCgjL08+b53mtfAM/SVsZcjKLtysQZPqIy9HPP3m/3zKItRwT0LyQo8sTr26tcO83DIUMWIJjierHLsJda/tbNBFY0BP/bKtcM8HNIWCK3aYR4OMzgxo5w5EFLOLKDExXAm9gI4E3iAO94/Ct/lKWuM2LMGbgAAAABJRU5ErkJggg==") 50% / 85% no-repeat;
}

.iziToast > .iziToast-body .iziToast-icon.ico-error {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAeFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAVyEiIAAAAJ3RSTlMA3BsB98QV8uSyWVUFz7+kcWMM2LuZioBpTUVBNcq2qaibj4d1azLZZYABAAACZElEQVRYw7WX25KCMAyGAxUoFDkpiohnV97/DXeGBtoOUprZ2dyo1K82fxKbwJJVp+KQZ7so2mX5oThVQLKwjDe9YZu4DF3ptAn6rxY0qQPOEq9fNC9ha3y77a22ba24v+9Xbe8v8x03dPOC2/NdvB6xeSreLfGJpnx0TyotKqLm2s7Jd/WO6ivXNp0tCy02R/aFz5VQ5wUPlUL5fIfj5KIlVGU0nWHm/5QtoTVMWY8mzIVu1K9O7XH2JiU/xnOOT39gnUfj+lFHddx4tFjL3/H8jjzaFCy2Rf0c/fdQyQszI8BDR973IyMSKa4krjxAiW/lkRvMP+bKK9WbYS1ASQg8dKjaUGlYPwRe/WoIkz8tiQchH5QAEMv6T0k8MD4mUyWr4E7jAWqZ+xWcMIYkXvlwggJ3IvFK+wIOcpXAo8n8P0COAaXyKH4OsjBuZB4ew0IGu+H1SebhNazsQBbWm8yj+hFuUJB5eMsN0IUXmYendAFFfJB5uEkRMYwxmcd6zDGRtmQePEykAgubymMRFmMxCSIPCRbTuFNN5OGORTjmNGc0Po0m8Uv0gcCry6xUhR2QeLii9tofbEfhz/qvNti+OfPqNm2Mq6105FUMvdT4GPmufMiV8PqBMkc+DdT1bjYYbjzU/ew23VP4n3mLAz4n8Jtv/Ui3ceTT2mzz5o1mZt0gnBpmsdjqRqVlmplcPdqa7X23kL9brdm2t/uBYDPn2+tyu48mtIGD10JTuUrukVrbCFiwDzcHrPjxKt7PW+AZQyT/WESO+1WL7f3o+WLHL2dYMSZsg6dg/z360ofvP4//v1NPzgs28WlWAAAAAElFTkSuQmCC") 50% / 80% no-repeat;
}

.iziToast > .iziToast-body .iziToast-icon.ico-success {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABABAMAAABYR2ztAAAAIVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABt0UjBAAAACnRSTlMApAPhIFn82wgGv8mVtwAAAKVJREFUSMft0LEJAkEARNFFFEw1NFJb8CKjAy1AEOzAxNw+bEEEg6nyFjbY4LOzcBwX7S/gwUxoTdIn+Jbv4Lv8bx446+kB6VsBtK0B+wbMCKxrwL33wOrVeeChX28n7KTOTjgoEu6DRSYAgAAAAkAmAIAAAAIACQIkMkACAAgAIACAyECBKAOJuCagTJwSUCaUAEMAABEBRwAAEQFLbCJgO4bW+AZKGnktR+jAFAAAAABJRU5ErkJggg==") 50% / 85% no-repeat;
}

.iziToast > .iziToast-body .iziToast-icon.ico-question {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfhCQkUEhFovxTxAAAEDklEQVRo3s2ZTWgTQRTHf03ipTRUqghNSgsRjHgQrFUQC6JgD1Kak3gQUUoPqRdBglf1oBehBws9Cn4cGk+1SOmh2upBxAYVoeJHrR9tgq0i1Cq0lqYeks7MbpPdmU00/c8hm9n33v/t7Nt5M2+qMEWQI0QIibZKRrQpHvLL2KI2wnQzzBKrDm2RIeKEy01dTYKUI7G1ZRknQXV5yP10kTYgly1NF/5S6duZ8ES+1iZodyaocrjXxE0OFeifYYgp0mRIkwFChAkRJsIxGgrIP+I0n82fvZW5dc/zkss0O2o1c5mX6/TmaDWl77RFe5YkUW3tKEmyFv0lOvXJ/fTYnmCEFuMRbGHEZqVHLyT9DFjUJmkzJl9DG5MWWwM6Llif/gF1nukB6nhgGwUXdFrE+wiURA8QoM9i0zEWWpXQW+ZsyeRrOMuyEo5Fv4gmy4dXPvqcC+pH2VRYaMwy+OWG+iLGCgm0W0Kv9HdvR8ASjmKCXpuK/bxiV/76A/v5UdDIZuKcJGjrnec5KZ7wwsWFOp6xPX/9mt2sqDe7FO+Kf/fXHBPPDWpdXGhTpLvUG9VKwh1xMDDjkvu+cNDFBTk7ptX1QkKZ850m3duu6fcrWxwdaFFyREJ2j4vOpKP6Du6z4uJCv8sYJIVkCnJBGGZaBONO3roY2EqNrSfIPi7SKP4fdXyNUd6I6wbSAHEl33tFLe+FlSsusnK90A0+oEPcuufZgXnOi+u9LrKSJQZQw6LwqBnv2CKsfHORbFbyQhA6xN/pEuihSdj56Co7LWRjPiKie6gkB2LiKuUqK5kiPkLiz1QJ9K1cNXBAMoUCigNpQ9IqDtMI1HKA4/jyvUsaoSyZLA5kjOjDPFZen8Ql5TsvBskUgjciIPSX3QAXC86DT7VWvlEh/xZ+ij9BDVWJ0QL0SbZq6QaFxoLPcXPmBLveLCc4wXdDK6s+6/vwhCSniFLPXW0NJe5UB8zKCsviqpc7vGPVQFcyZbyPwGD+d5ZnxmNWlhG4xSBZZjivjIWHEQgoDkSMjMwTo54569JSE5IpA7EyJSMTyGTUAUFlO1ZKOtaHTMeL1PhYYFTcihmY2cQ5+ullj7EDkiVfVez2sCTz8yiv84djhg7IJVk81xFWJlPdfHBG0flkRC/zQFZ+DSllNtfDdUsOMCliyGX5uOzU3ZhIXFDof4m1gDuKbEx0t2YS25gVGpcMnr/I1kx3c6piB8P8ZoqEwfMX3ZyCXynJTmq/U7NUXqfUzCbWL1wqVKBQUeESzQYoUlW8TAcVL1RCxUu1G6BYXfFyfQ4VPbDI4T8d2WzgQ6sc/vmxnTsqfHCZQzUJxm1h5dxS5Tu6lQgTZ0ipqRVqSwzTbbLHMt+c19iO76tsx/cLZub+Ali+tYC93olEAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE3LTA5LTA5VDIwOjE4OjE3KzAyOjAwjKtfjgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNy0wOS0wOVQyMDoxODoxNyswMjowMP325zIAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC") 50% / 85% no-repeat;
}

.iziToast > .iziToast-body .iziToast-title {
  text-align: left;
  float: left;
  color: var(--color-text-inverted);
  white-space: normal;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 16px;
}

.iziToast > .iziToast-body .iziToast-message {
  text-align: left;
  float: left;
  color: var(--color-text-inverted);
  white-space: normal;
  margin: 0 0 10px;
  padding: 0;
  font-size: 14px;
  line-height: 16px;
}

.iziToast.iziToast-animateInside .iziToast-title, .iziToast.iziToast-animateInside .iziToast-message, .iziToast.iziToast-animateInside .iziToast-icon, .iziToast.iziToast-animateInside .iziToast-buttons-child, .iziToast.iziToast-animateInside .iziToast-inputs-child {
  opacity: 0;
}

.iziToast-target {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.iziToast-target .iziToast-capsule {
  overflow: hidden;
}

.iziToast-target .iziToast-capsule:after {
  visibility: hidden;
  content: " ";
  clear: both;
  height: 0;
  font-size: 0;
  display: block;
}

.iziToast-target .iziToast-capsule .iziToast {
  float: left;
  width: 100%;
}

.iziToast-wrapper {
  z-index: 99999;
  pointer-events: none;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: fixed;
}

.iziToast-wrapper .iziToast.iziToast-balloon:before {
  border-top: 10px solid #000;
  border-left: 15px solid #0000;
  border-right: 0 solid #0000;
  border-top-color: inherit;
  left: auto;
  right: 8px;
}

.iziToast-wrapper-bottomLeft {
  text-align: left;
  bottom: 0;
  left: 0;
}

.iziToast-wrapper-bottomLeft .iziToast.iziToast-balloon:before {
  border-left: 0 solid #0000;
  border-right: 15px solid #0000;
  left: 8px;
  right: auto;
}

.iziToast-wrapper-bottomRight {
  text-align: right;
  bottom: 0;
  right: 0;
}

.iziToast-wrapper-topLeft {
  text-align: left;
  top: 0;
  left: 0;
}

.iziToast-wrapper-topLeft .iziToast.iziToast-balloon:before {
  border-left: 0 solid #0000;
  border-right: 15px solid #0000;
  left: 8px;
  right: auto;
}

.iziToast-wrapper-topRight {
  text-align: right;
  top: 0;
  right: 0;
}

.iziToast-wrapper-topCenter {
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
}

.iziToast-wrapper-bottomCenter {
  text-align: center;
  bottom: 0;
  left: 0;
  right: 0;
}

.iziToast-wrapper-center {
  text-align: center;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  inset: 0;
}

@media only screen and (width >= 568px) {
  .iziToast-wrapper {
    padding: 10px 15px;
  }

  .iziToast {
    border-radius: .5rem;
    width: auto;
    margin: 5px 0;
  }

  .iziToast:not(.iziToast-rtl) .iziToast-cover {
    border-radius: 3px 0 0 3px;
  }

  .iziToast.iziToast-rtl .iziToast-cover {
    border-radius: 0 3px 3px 0;
  }

  .iziToast.iziToast-color-dark:after {
    box-shadow: inset 0 -10px 20px -10px #ffffff4d, 0 10px 10px -5px #00000040;
  }

  .iziToast.iziToast-balloon .iziToast-progressbar {
    background: none;
  }

  .iziToast.iziToast-balloon:after {
    box-shadow: 0 10px 10px -5px #00000040, inset 0 10px 20px -5px #00000040;
  }

  .iziToast-target .iziToast:after {
    box-shadow: inset 0 -10px 20px -10px #0003, inset 0 0 5px #0000001a;
  }
}

.iziToast.fadeInUp {
  animation: .7s both iziT-fadeInUp;
}

.iziToast.fadeOut {
  animation: .7s both iziT-fadeOut;
}

.iziToast.fadeOutDown {
  animation: .7s cubic-bezier(.4, .45, .15, .91) both iziT-fadeOutDown;
}

.iziToast-overlay.fadeOut {
  animation: .7s both iziT-fadeOut;
}

@keyframes iziT-fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes iziT-fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes iziT-fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.iziToast.slideIn, .iziToast .slideIn {
  animation: 1s cubic-bezier(.16, .81, .32, 1) both iziT-slideIn;
}

@keyframes iziT-slideIn {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.article-snippet {
  background: var(--color-background-section);
  border-radius: 1.6rem;
  flex-direction: column;
  gap: .8rem;
  padding: 1.6rem;
  display: flex;
  position: relative;
}

.article-snippet-title {
  margin: 0;
  display: block;
}

.article-snippet-link {
  color: var(--color-text);
  text-underline-offset: .5ex;
  font-size: 1.8rem;
  font-weight: 650;
  text-decoration: underline #0000;
}

.article-snippet-link:hover {
  -webkit-text-decoration-color: var(--color-text);
  text-decoration-color: var(--color-text);
  transition: text-decoration-color .3s;
}

.article-snippet-link:before {
  content: "";
  position: absolute;
  inset: 0;
}

.block-list {
  flex-direction: column;
  gap: .8rem;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.block-list > li {
  padding: 0;
}

@media screen and (width >= 1216px) {
  .island-layout {
    margin-block: -1.6rem;
    display: flex;
  }

  .island-layout--reverse {
    flex-direction: column-reverse;
  }

  .island {
    animation-duration: auto;
    animation-timing-function: linear;
    animation-direction: both;
    animation-range: entry 25% cover 50%;
    max-width: 300px;
    font-style: italic;
    animation-name: reveal;
    animation-timeline: view();
    position: absolute;
    right: 0;
    transform: translate(calc(100% + 3.2rem), -1.6rem);
  }

  .island > .active-block {
    margin: 0;
  }

  .island > .active-block figcaption {
    display: none;
  }

  .island-code {
    border-radius: 0;
    padding: 0;
  }

  .island-code .code-line-numbers {
    display: none;
  }
}

@keyframes reveal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.selection {
  border-radius: .4rem;
}

.selection--code {
  background: #00b2ff33;
}

.selection--code-error {
  -webkit-text-decoration-color: var(--color-token-red);
  text-decoration-color: var(--color-token-red);
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  text-underline-position: from-font;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  text-decoration-line: underline;
  text-decoration-style: wavy;
}

.banner {
  background-color: var(--color-light-blue);
  border-radius: 1.6rem;
  gap: 1.6rem;
  padding: 3.2rem;
  display: flex;
}

@media screen and (width <= 599.999px) {
  .banner {
    padding: 3.2rem 1.6rem;
  }
}

.banner-content {
  flex-direction: column;
  display: flex;
}

.banner-title {
  margin: 0;
  font-size: 1.8rem;
}

.banner-text {
  margin: 0;
  margin-block-start: .8rem;
}

.banner-action {
  margin-block-start: 1.6rem;
}

.banner-image {
  width: auto;
  height: 150px;
}

@media (width <= 600px) {
  .banner-image {
    display: none;
  }
}

.footer {
  margin-block: 4.8rem;
}

.button {
  z-index: 1;
  width: fit-content;
  padding: 1.6rem;
  font-weight: 650;
  text-decoration: none;
  display: flex;
  position: relative;
}

.button:before {
  content: "";
  z-index: -1;
  border-radius: .8rem;
  transition: transform .3s;
  position: absolute;
  inset: 0;
}

.button:hover:before {
  transform: scale(1.1);
}

.button_inverted {
  color: var(--color-text);
}

.button_inverted:before {
  background-color: var(--color-background-section);
}

@supports not (font-variation-settings: normal) {
  @font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 400;
    font-display: optional;
    size-adjust: 106.25%;
    src: url("Regular.82d38850.woff2") format("woff2"), url("Regular.07290a8e.woff") format("woff"), url("Regular.7272ba1a.ttf") format("truetype");
  }

  @font-face {
    font-family: "Source Sans 3";
    font-style: italic;
    font-weight: 400;
    font-display: optional;
    size-adjust: 106.25%;
    src: url("Italic.5ac0e7a5.woff2") format("woff2"), url("Italic.8466c0f9.woff") format("woff"), url("Italic.553b788f.ttf") format("truetype");
  }

  @font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 600;
    font-display: optional;
    size-adjust: 106.25%;
    src: url("SemiBold.f3da821f.woff2") format("woff2"), url("SemiBold.6b2f524f.woff") format("woff"), url("SemiBold.08d31dd3.ttf") format("truetype");
  }
}

@supports (font-variation-settings: normal) {
  @font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 200 900;
    font-display: optional;
    size-adjust: 106.25%;
    src: url("Variable.3a95d8cd.woff2") format("woff2-variations");
    src: url("Variable.3a95d8cd.woff2") format("woff2") tech("variations");
  }

  @font-face {
    font-family: "Source Sans 3";
    font-style: italic;
    font-weight: 200 900;
    font-display: optional;
    size-adjust: 106.25%;
    src: url("ItalicVariable.1f2b7a55.woff2") format("woff2-variations");
    src: url("ItalicVariable.1f2b7a55.woff2") format("woff2") tech("variations");
  }
}

@font-face {
  font-family: JetBrains Mono;
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url("Regular.2f386850.woff2") format("woff2");
}

@font-face {
  font-family: JetBrains Mono;
  font-style: italic;
  font-weight: 400;
  font-display: optional;
  src: url("Italic.37fe1585.woff2") format("woff2");
}

@view-transition {
  navigation: auto;
}

::view-transition-old(root) {
  animation: .5s view-out;
}

::view-transition-new(root) {
  animation: .5s view-in;
}

@keyframes view-out {
  from {
    translate: 0;
  }

  to {
    translate: 0 -30vh;
  }
}

@keyframes view-in {
  from {
    translate: 0 100vh;
  }

  to {
    translate: 0;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

:root {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  color: var(--color-text);
  background-color: var(--color-background);
  font-family: "Source Sans 3", Helvetica, Arial, sans-serif;
  font-size: var(--size-text-m);
  line-height: 1.5;
}

h1 {
  margin-block: 4.8rem;
  line-height: 1.2;
}

h2 {
  margin-block: 4.8rem 1.6rem;
  line-height: 1.2;
}

h1, h2, h3, h4, h5, h6, strong, b, th {
  font-weight: 650;
}

h1, h2, h3, h4, h5, h6 {
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
  scroll-margin-top: 3.2rem;
}

@supports not (scroll-margin-top: 0) {
  h1, h2, h3, h4, h5, h6 {
    scroll-snap-margin-top: 3.2rem;
  }
}

pre {
  margin: 0;
}

pre > code {
  padding: 0;
}

code, kbd, samp {
  background-color: var(--color-background);
  font-family: JetBrains Mono, Segoe UI Mono, Ubuntu Mono, Roboto Mono, Menlo, Monaco, Consolas, monospace;
  font-size: 1.4rem;
}

code {
  border-radius: .5ex;
  padding: 0 .5ex;
}

ol, ul {
  padding-inline-start: 3.2rem;
}

ol > li, ul > li {
  padding-inline-start: 1.6rem;
}

ul > li::marker {
  content: "✦";
}

.limit-width {
  max-width: 700px;
}

.table-wrapper {
  margin: 2rem 0;
  position: relative;
}

.table {
  border-collapse: separate;
  table-layout: auto;
  border-spacing: 0;
  caption-side: bottom;
  margin: 2em 0;
}

.table th:first-of-type {
  border-top-left-radius: .5em;
}

.table th:last-of-type {
  border-top-right-radius: .5em;
}

.table tbody > tr:last-child td:first-of-type {
  border-bottom-left-radius: .5em;
}

.table tbody > tr:last-child td:last-of-type {
  border-bottom-right-radius: .5em;
}

.table th {
  text-align: left;
  border-top: 1px solid #cecece;
}

.table td, .table th {
  border-bottom: 1px solid #cecece;
  border-left: 1px solid #cecece;
  padding: .25em .5em;
}

.table td:last-child, .table th:last-child {
  border-right: 1px solid #cecece;
}

.table caption {
  font-size: var(--size-text-s);
  text-align: left;
  margin-top: .25em;
}

.visually-hidden {
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}
/*# sourceMappingURL=index.9aee3f1e.css.map */
